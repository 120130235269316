<!-- Localized -->
<template>
  <div class="test-page page2 overflow-y-hidden">
    <DependentFields />
  </div>
</template>

<script>
import DependentFields from "@shared/components/dependent-fields";
export default {
  name: "page2",
  components: {
    DependentFields,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>


<style lang="scss" scoped>
</style>